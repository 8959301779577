/**
 * Этот файл содержит функционал для динамической инициализации различных модулей и обработки UI элементов.
 * Основные функции:
 *
 * - Динамическая инициализация модулей Shopaholic и GLightbox при загрузке страницы.
 * - Инициализация и настройка Swiper для различных слайдеров.
 * - Управление видимостью опций фильтров.
 */


let inited = false;
document.addEventListener('page:loaded', startFrontController);
console.log('прогрузил js дом');
if(inited){
    document.addEventListener('DOMContentLoaded', startFrontController);
    console.log('прогрузил js турбо');
}
function startFrontController() {
    inited = true;
    // Динамический импорт и инициализация Shopaholic
    import('./shopaholicInit').then(({ default: initShopaholic }) => {
        initShopaholic();
    }).catch(error => console.error('Ошибка загрузки Shopaholic:', error));

    // Динамический импорт и инициализация GLightbox
    // import('glightbox').then(({ default: GLightbox }) => {
    //     const lightbox = GLightbox({
    //         selector: '.glightbox'
    //     });
    // }).catch(error => console.error('Ошибка загрузки GLightbox:', error));

    // Сначала скрываем все блоки с опциями фильтров
    // document.querySelectorAll('.filter-options').forEach(function (el) {
    //     el.style.display = 'flex';
    // });

    // // Добавляем обработчик события на клик по кнопкам фильтров
    // document.querySelectorAll('.filter-button').forEach(function (button) {
    //     button.addEventListener('click', function () {
    //         // Определяем следующий элемент после кнопки - наш блок с опциями
    //         const options = this.nextElementSibling;
    //
    //         // Проверяем состояние видимости блока с опциями
    //         const isVisible = options.style.display === 'none';
    //
    //         // Переключаем видимость блока с опциями
    //         options.style.display = isVisible ? 'flex' : 'none';
    //
    //         // Переключаем поворот стрелки
    //         const arrow = this.querySelector('img');
    //         if (arrow) {
    //             arrow.style.transform = isVisible ? 'rotate(-90deg)' : 'rotate(90deg)';
    //         }
    //     });
    // });

    // initializeSwipers();
    //
    // function initializeSwipers() {
    //     import('swiper').then(({ default: Swiper }) => {
    //         const galleryThumbs = new Swiper('.js-swiper-gallery-thumbs', {
    //             spaceBetween: 10,
    //             slidesPerView: 5,
    //             freeMode: true,
    //             watchSlidesVisibility: true,
    //             watchSlidesProgress: true,
    //         });
    //
    //         new Swiper('.js-swiper-gallery-main', {
    //             navigation: {
    //                 nextEl: '.js-swiper-gallery-button-next',
    //                 prevEl: '.js-swiper-gallery-button-prev',
    //             },
    //             thumbs: {
    //                 swiper: galleryThumbs
    //             }
    //         });
    //
    //         const swiperConfigs = [
    //             {
    //                 selector: '.jewelry-slider',
    //                 nextEl: '.jewelry-next',
    //                 prevEl: '.jewelry-prev',
    //                 slidesPerView: 3,
    //             },
    //         ];
    //
    //         swiperConfigs.forEach((config) => {
    //             new Swiper(config.selector, {
    //                 effect: config.effect || 'slide',
    //                 navigation: {
    //                     nextEl: config.nextEl,
    //                     prevEl: config.prevEl,
    //                 },
    //                 slidesPerView: config.slidesPerView || 1,
    //                 slidesPerGroup: 1,
    //                 loop: true,
    //                 rewind: true,
    //                 watchOverflow: true,
    //                 spaceBetween: 25,
    //                 breakpoints: {
    //                     320: { slidesPerView: 1 },
    //                     640: { slidesPerView: 2 },
    //                     1024: { slidesPerView: config.slidesPerView || 1, spaceBetween: 20 },
    //                     1280: { spaceBetween: 25 },
    //                 },
    //                 ...config.flipEffect ? { flipEffect: config.flipEffect, speed: config.speed } : {}
    //             });
    //         });
    //     }).catch(error => console.error('Ошибка загрузки Swiper:', error));
    // }
    //


    // const arrows = document.querySelectorAll('.category-item .arrow');
    //
    // arrows.forEach(arrow => {
    //     arrow.addEventListener('click', function(event) {
    //         event.stopPropagation(); // Останавливаем всплытие, чтобы ссылка не срабатывала
    //
    //         const parentItem = arrow.closest('.category-item');
    //         parentItem.classList.toggle('open');
    //
    //         const subcategoryList = parentItem.querySelector('.subcategory-list');
    //         if (subcategoryList) {
    //             subcategoryList.style.display = subcategoryList.style.display === 'block' ? 'none' : 'block';
    //         }
    //     });
    // });

}
